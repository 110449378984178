<!--
 * @Description: 修改密码弹层
 * @Author: 前胡
 * @LastEditors: 前胡
 * @Date: 2020-03-13 10:56:09
 * @LastEditTime: 2020-03-31 19:06:24
 -->
 <template>
  <div class="cancel-apply-modal">
    <a-modal title="修改密码" v-model="changePasswordModalVisible" @cancel="handleHideThisModal()">
      <a-form :form="changePasswordForm">
        <a-form-item label="旧密码" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
          <a-input
            placeholder="请填写旧密码"
            type="password"
            v-decorator="['old_password', { rules: [{ required: true, message: '请输入旧密码' },{ max:15, message: '最多15个字',},{ min:8, message: '最少8个字',}]}]"
          />
        </a-form-item>
        <a-form-item label="新密码" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
          <a-input
            placeholder="请填写新密码"
            type="password"
            v-decorator="['new_password', { rules: [{ required: true, message: '请输入新密码' },{ max:15, message: '最多15个字',},{ min:8, message: '最少8个字',}]}]"
          />
        </a-form-item>
        <a-form-item label="确认新密码" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
          <a-input
            placeholder="请填写确认新密码"
            type="password"
            v-decorator="['new_password2', { rules: [{ required: true, message: '请输入确认新密码' },{ max:15, message: '最多15个字',},{ min:8, message: '最少8个字',},{
                validator: compareToFirstPassword,
              },]}]"
          />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <a-button key="back" @click="handleHideThisModal()">取消</a-button>
        <a-button key="submit" type="primary" :loading="isPosting" @click="handleCancelApplyData">确定</a-button>
      </template>
    </a-modal>
  </div>
</template>
<script>
import { updatePassword } from "@/service/admin";
export default {
  name: "ChangePasswordModal",
  data() {
    return {
      changePasswordForm: this.$form.createForm(this),
      changePasswordModalVisible: true,
      old_password: "",
      new_password: "",
      new_password2: "",
      isPosting: false
    };
  },
  methods: {
    // 提交数据
    handleCancelApplyData() {
      this.changePasswordForm.validateFields(async (err, values) => {
        if (!err) {
          this.isPosting = true;
          const { err, res } = await updatePassword(values);
          this.isPosting = false;
          if (!err) {
            if (res.success) {
              this.$message.success(res.message);
              this.handleHideThisModal(true);
            }
          }
        }
      });
    },
    // 隐藏modal
    handleHideThisModal(isNeedParent = false) {
      this.$emit("callback", isNeedParent);
    },
    // 验证密码是否相同
    compareToFirstPassword(rule, value, callback) {
      const form = this.changePasswordForm;
      if (value && value !== form.getFieldValue("new_password")) {
        callback("两次密码不相同!");
      } else {
        callback();
      }
    }
  }
};
</script>
<style lang="less" scoped>
.mb-20 {
  margin-bottom: 20px;
}
.user-other-address-modal {
  width: 800px;
}
.address-list {
  margin: 0;
  padding: 0;
  list-style: none;
  .address-item {
    margin-bottom: 10px;
    .ai-name {
      width: 150px;
      display: inline-block;
    }
    .ai-phone {
      width: 120px;
      display: inline-block;
    }
    .ai-address {
    }
  }
}
</style>