<!--
 * @Description: 管理员首页
 * @Date: 2019-12-21 17:40:13
 * @LastEditTime: 2020-06-19 10:06:58
 * @LastEditors: 前胡
 -->
<template>
  <a-layout id="components-layout-custom-trigger">
    <!-- 侧边栏 -->
    <a-layout-sider :trigger="null" collapsible v-model="collapsed" width="180"
                    style="overflow-y:auto;" theme="dark">
      <!-- logo -->
      <div class="logo" :style="{width:collapsed?'auto':'130px'}">
        <span>折一</span>
        <span v-show="!collapsed">管理系统</span>
      </div>
      <!-- 菜单 -->
      <a-menu theme="dark" mode='inline' :defaultSelectedKeys="[$route.path]" :openKeys="defaultOpenKeys"
              :defaultOpenKeys="defaultOpenKeys" v-model="selectedKeys" @select="handleMenuSelected">
        <template v-for="(nav,index) in navList">
          <template>
            <!-- 下拉菜单 -->
            <a-sub-menu :index="index"
                        v-if="nav.children && nav.children.length > 0"
                        :key="nav.path"
                        @titleClick="subTitleClick"
                        :class="nav.path === selectOneKey ?'submenu-selected':''"
                        :style="{width:collapsed?'auto':'170px'}">
              <span slot="title"><a-icon :type="nav.meta.icon"/><span>{{nav.meta.title}}</span></span>
              <template v-if="shownav">
                <template v-for="subnav in nav.children">
                  <a-menu-item @click="jump(nav.path,subnav.path)" v-if="!subnav.hidden"
                               :key="`${nav.path}-${subnav.path}`">{{subnav.meta.title}}
                  </a-menu-item>
                </template>
              </template>
            </a-sub-menu>
            <!-- 顶级菜单 -->
            <a-menu-item v-else :key="nav.path" :class="nav.path === selectOneKey?'submenu-selected':''">
              <a-icon :type="nav.meta.icon"/>
              <span>{{nav.meta.title}}</span>
            </a-menu-item>
          </template>
        </template>
      </a-menu>
    </a-layout-sider>
    <!-- 主体部分 -->
    <a-layout class="layout-content">
      <!-- 主体顶部 -->
      <a-layout-header :class="['fixed-layout-header', collapsed && 'header-menu-fold']"
                       style="background: #ffffff; padding: 0">
        <div class="header-div">
          <a-icon class="trigger" :type="collapsed ? 'menu-unfold' : 'menu-fold'" @click="selectshowDraw"/>
          <p class="bread-crumbs">{{routeName && routeName !== subRouteName ? (routeName+' / ') : ''}}  {{subRouteName}}</p>
        </div>
        <!-- <span class="page-title" v-if="collapsed">{{pageTitle()}}</span> -->
        <!-- 右侧 用户信息 -->
        <div class="right-area-wrap" style="padding-right: 16px">
          <a-dropdown v-if="userinfo" :trigger="['click']">
            <span class="right-area username">欢迎，{{userinfo.username}} <a-icon type="down"/></span>
            <a-menu slot="overlay" @click="onClick">
              <a-menu-item key="changePassword">修改密码</a-menu-item>
              <!-- <a-menu-divider /> -->
              <a-menu-item key="logout">退出</a-menu-item>
            </a-menu>
          </a-dropdown>
        </div>
      </a-layout-header>
      <!-- 主体内容（页面） -->
      <a-layout-content class="layout-content main-content">
        <transition name="fade" mode="out-in">
          <router-view></router-view>
        </transition>
      </a-layout-content>
    </a-layout>
    <ChangePasswordModal v-if="isChangePasswordModalVisiable" @callback="hidePasswordModal"/>
  </a-layout>
</template>
<script>
  import ChangePasswordModal from "@/components/admin/change-password-modal";

  export default {
    name: 'home',
    components: {
      ChangePasswordModal
    },
    data() {
      return {
        shownav: true,
        collapsed: false,
        navList: [],
        selectedKeys: [],
        selectOneKey: undefined,
        defaultOpenKeys: [],
        userinfo: null,
        isChangePasswordModalVisiable: false,
        routeName: null,
        subRouteName: null
      }
    },
    created() {
      var route = this.$store.state.user.routes;
      // 侧边离菜单列表
      this.navList = route.find(item => item.path === '/admin').children
      // 用户信息
      this.userinfo = this.$store.getters.getUserinfo;
      this.judgeNewPage();
      // 浏览器控制按钮前进后退触发函数
      // window.addEventListener('popstate', this.popstate, false);
      // 监听路由变化，修改顶部面包屑文字
      document.addEventListener('click', (e) => {
       if (this.collapsed && this.shownav) {
            if(e.x>99){
            this.shownav = false;
          }
        }
      })
    },
    // 监听,当路由发生变化的时候执行
    watch: {
      $route: {
        handler: function () {
          this.judgeNewPage();
        },
        // 深度观察监听
        deep: true
      }
    },
    /* destroyed() { // 销毁vm组件
       // 避免堆栈溢出，多次创建、多次触发
       window.removeEventListener('popstate', this.popstate, false);
     },*/
    methods: {
      selectshowDraw() {
        this.collapsed = !this.collapsed;
        if (this.collapsed) {
          this.shownav = false;
        } else {
          this.shownav = true;
        }
      },
      judgeNewPage() {
        if (this.collapsed) {
          this.shownav = false;
        } else {
          this.shownav = true;
        }
        /* const route = this.$store.state.user.routes;
      this.route = route
        .find(item => item.path === "/admin")
        .children.find(item => item.path === "shop");
      let subPath = this.$route.path.split("/")[3];
      if(this.$route.path.split('/').length>4){
        this.isshowgoback=true;
        subPath = this.$route.path.split("/")[3] + '/' + this.$route.path.split("/")[4];
      }else{
        this.isshowgoback=false;
      }
      this.subRouteName = this.route.children.find(
        item => item.path === subPath
      ).name;*/
        // 左侧菜单激活状态，先从path中拿到模块名称，如：/admin/user中的user，再拿到导航中path包含user的菜单选项的索引

        const moduleNames = this.$route.path.split('/');
        this.routeName = this.$route.matched[this.$route.matched.length-2].name || '';
        this.subRouteName = this.$route.name || '';
        // this.selectedKeys = [this.navList.findIndex(item => item.path.includes(moduleName))]
        if (moduleNames.length === 3) { // 一级
          this.selectedKeys = [moduleNames[2]];
          this.selectOneKey = moduleNames[2];
        } else if (moduleNames.length === 4 || moduleNames.length === 5) { // 二级
          if(moduleNames[3]==='spu'){
            this.selectedKeys = [`${moduleNames[2]}-${moduleNames[3]}/${moduleNames[4]}`]
            this.defaultOpenKeys = [moduleNames[2]]
            this.selectOneKey = moduleNames[2];
          }else{
            this.selectedKeys = [`${moduleNames[2]}-${moduleNames[3]}`]
            this.defaultOpenKeys = [moduleNames[2]]
            this.selectOneKey = moduleNames[2];
          }

        }
      },
      popstate() {
        this.judgeNewPage();
      },
      jump(nav, subNav) {
        if (this.collapsed) {
          this.shownav = false;
        }
        const currentPath = this.$router.currentRoute.path;
        const targetPath = `/admin/${nav}/${subNav}`
        if (currentPath !== targetPath) {
          this.$router.push({path: targetPath})
        }
      },
      // 侧边菜单选中事件回调
      handleMenuSelected(menu) {
        if (!menu.key.includes('-')) { // 一级
          this.selectedKeys = [menu.key]
          this.defaultOpenKeys = []
          this.$router.push({path: `/admin/${menu.key}`})
        } else { // 二级
          // const keys = menu.key.split('-')
          // this.selectedKeys = [`${keys[0]}-${keys[1]}`]
          // this.defaultOpenKeys = [keys[0]]
          // this.$router.push({ path: `/admin/${keys[0]}/${keys[1]}` })
        }
      },
      // 二级菜单标题点击
      subTitleClick(menu) {
        if(this.defaultOpenKeys.length>0 && this.defaultOpenKeys.indexOf(menu.key)>=0){
          this.defaultOpenKeys = [];
          this.shownav = false;
          return;
        }
        this.defaultOpenKeys = [menu.key];
        this.shownav = true;
      },
      // 用户信息下拉点击事件回调
      onClick({key}) {
        if (key === 'logout') {
          this.$store.commit('logout')
          window.location.href = '/'
          // this.$router.push('/login')
        }
        if (key === 'changePassword') {
          this.isChangePasswordModalVisiable = true
        }
      },
      // 隐藏修改密码弹层
      hidePasswordModal(isNeedRefresh) {
        this.isChangePasswordModalVisiable = false;
        if (isNeedRefresh) {
          this.$store.commit('logout')
          window.location.href = '/'
          // this.$router.push('/login')
        }
      }
    }
  }
</script>
<style lang="less">

.ant-layout-sider-children,.ant-layout-sider {
  /* 滚动槽 */
  &::-webkit-scrollbar-track-piece {
    //border-radius: 4px;
    background: rgba(0, 0, 0, 0.1);
  }
  &::-webkit-scrollbar-thumb:hover {
    background: rgba(176, 168, 168, 0.5);
  }
}
.ant-menu {
  > .ant-menu-submenu {
    &.submenu-selected {
      .ant-menu-submenu-title {
        color: white;
      }
      .ant-menu-submenu-arrow {
        opacity: 1;
      }
    }
  }
}
  #components-layout-custom-trigger .trigger {
    font-size: 18px;
    line-height: 64px;
    padding: 0 24px;
    cursor: pointer;
    transition: color 0.3s;
  }

  #components-layout-custom-trigger .trigger:hover {
    color: #1890ff;
  }

  #components-layout-custom-trigger .logo {
    height: 32px;
    background: rgba(255, 255, 255, 0.2);
    margin:18px 16px;
    text-align: center;
    overflow: hidden;
    border-radius: 5px;
    width: 130px;
    span {
      -webkit-transition: all 0.2s;
      transition: all 0.2s;
      line-height: 32px;
      color: #fff;
    }
  }

  #components-layout-custom-trigger {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .fixed-layout-header {
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    width: calc(100% - 160px);
    position: fixed;
    top: 0;
    height: 50px;
    line-height: 50px;
    left: 180px;
    z-index: 1;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
    .trigger {
      height: 50px !important;
      line-height: 50px !important;
    }
    .header-div {
      display: flex;
    }
    &.header-menu-fold {
      width: calc(100% - 80px);
      left: 80px;
    }

    .page-title {
      position: relative;
      top: -2px;
    }

    // 用户信息
    .right-area-wrap {
      display: inline-block;
      position: absolute;
      right: 0;
      top: 0;

      .username {
        display: inline-block;
        padding: 0 20px;
        cursor: pointer;
      }
    }
  }

  .layout-content {
    margin-top: 50px;
    background: #FCF9FA;
    z-index: 2;
  }
  .main-content {
    margin: 16px;
    height: initial;
    flex: none;
  }
</style>
